<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 </div>

  <div id="fullpage">
    <div class="section" id="section0">

      <div class="swiper hsSwiper">
      <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in data1" :id="forId(index)">
        <div class="intro">
          <p>
            <img :src="item.images" alt="辉山产品"/>
          </p>
        </div>
        </div>
      </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>

    </div>
    <div class="section" id="section1">


      <div class="swiper hsSwiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in data2">
            <div class="intro">
              <p>
                <img :src="item.images" alt="辉山产品"/>
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>

    </div>
    <div class="section" id="section2">
      <div class="swiper hsSwiper2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in data3">
            <div class="intro">
              <p>
                <img :src="item.images" alt="辉山产品"/>
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
<!--    <div class="section" id="section3">-->
<!--      <div class="intro">-->
<!--        <div class="cpsy_lay" id="cpzs1">-->
<!--          <div>-->

<!--            <form @submit.prevent="syysousuo">-->
<!--              <p>-->
<!--              <input type="text" maxlength="10" id="scode" name="scode" v-model="scode">-->
<!--              <input type="submit" value="查询" class="sy_btn" />-->
<!--              </p>-->
<!--          </form>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div id="sideNav" class="side-nav side-nav-0">
    <ul class="side-nav-ul" id="menu">
      <li class="side-nav-item"  data-menuanchor="firstPage"><a href="#firstPage" hidefocus="ture">液态奶</a></li>
      <li class="side-nav-item" data-menuanchor="secondPage"><a href="#secondPage" hidefocus="ture">奶粉</a></li>
      <li class="side-nav-item" data-menuanchor="3rdPage"><a href="#3rdPage" hidefocus="ture">创意乳品</a></li>
<!--      <li class="side-nav-item" data-menuanchor="4thpage"><a href="#4thpage" hidefocus="ture">产品追溯</a></li>-->
    </ul>
  </div>


  </div>
</template>
<style>
#top {
  position: fixed;
  z-index: 9;
}
#menu {
width: 77px;
}
#menu li a {
border: none;
}
.side-nav {
position: fixed;
*zoom: 1;
right: 100px;
top: 50%;
/*margin-top: -266px;*/
  z-index: 999
}

.side-nav-ul {
  position: relative;
  *zoom: 1;
  width: 168px;
  vertical-align: top
}

.side-nav-item {
  display: block;
  width: 100%;
  height: 52px;
  padding: 15px 0;
  margin: 0 0 1px;
  background-color: #f18f3b;
  white-space: nowrap
}

.side-nav-item:hover,.active {
  background-color: #acb800
}

.side-nav-item a {
  color: #fff;
}

.side-nav-item a:link,.side-nav-item a:visited {
  color: #fff
}



.swiper {
  max-width: 100%;
  max-height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #C63D0F;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  max-height: 666px;
  object-fit: cover;
}
.swiper-button-prev, .swiper-button-next{
  color: #fff;
}
</style>
<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import axios from 'axios'

import $ from "jquery/dist/jquery.min.js";
window.$ = $;
import 'jquery-ui-dist/jquery-ui.min.js'
import 'jquery-ui-dist/jquery-ui.min.css'
import {GETLISTDATA} from '../apis/api.js'
import fullpage from "fullpage.js/dist/fullpage.js";
import "fullpage.js/dist/fullpage.css";

import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper.min.css';


export default {
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
      data1:[],
      data2:[],
      data3:[],
      scode:'',
    };
  },
  mounted() {
	  this.showlunbo();
    var myFullpage = new fullpage('#fullpage', {
      anchors: ['firstPage', 'secondPage', '3rdPage', '4thpage'],
      sectionsColor: ['#C63D0F', '#1BBC9B', '#7E8F7C','#C63D0F'],
      menu:'#menu',
      navigation: false,
      // scrollOverflow: true,
      autoScrolling:true,
      responsive: 900,
      fitSection: false,
      navigationPosition: 'right',
      // navigationTooltips: ['First page', 'Second page', 'Third and last page']
    });
  },
  methods:{
     showlunbo(){
       GETLISTDATA({catid:21,pageNum:1,pageSize:20}).then((res) => {
        if(res.code == 200){
          this.data1 = res.rows;
          var swiper = new Swiper(".hsSwiper", {
            autoplay:true,
            observer:true,
            observeParents:true,
            slidesPerView: "auto",
            pagination: {
              el: ".swiper-pagination",
              type: "fraction",
            },
            navigation: {
              nextEl: ".hsSwiper .swiper-button-next",
              prevEl: ".hsSwiper .swiper-button-prev",
            },
          });
        }
      });
       GETLISTDATA({catid:22,pageNum:1,pageSize:10}).then((res) => {
         if(res.code == 200){
           this.data2 = res.rows;
           var swiper = new Swiper(".hsSwiper1", {
             autoplay:true,
             observer:true,
             observeParents:true,
             pagination: {
               el: ".swiper-pagination",
               type: "fraction",
             },
             navigation: {
               nextEl: ".hsSwiper1 .swiper-button-next",
               prevEl: ".hsSwiper1 .swiper-button-prev",
             },
           });
         }
       });
       GETLISTDATA({catid:23,pageNum:1,pageSize:10}).then((res) => {
         if(res.code == 200){
           this.data3 = res.rows;
           var swiper = new Swiper(".hsSwiper2", {
             autoplay:true,
             observer:true,
             observeParents:true,
             pagination: {
               el: ".swiper-pagination",
               type: "fraction",
             },
             navigation: {
               nextEl: ".hsSwiper2 .swiper-button-next",
               prevEl: ".hsSwiper2 .swiper-button-prev",
             },
           });
         }
       });
			
	  },
    forId:function(index) {
      return "slide" + (index+1);
    },
      syysousuo(event) {
      if(this.scode){
        window.location.href="/searchlist?code="+this.scode;
      }
	  		// this.$router.push({
	  		// path:'/searchlist1',
	  		//  query:{
	  		//   title:this.sy_sousuo
	  		//  }
	  		// });
	  }
	  //searchpost
  }
}
</script>